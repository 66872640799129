import React from "react";
import HomeLanding from "../HeroLanding";
import { graphql, Link, useStaticQuery } from "gatsby";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import HeroTitleOutline from "../HeroOutlineTitle";
import { black1, brandColor3, white } from "../styles/Colors";
import HeroDivideRight from "../HeroDivideRight";
import { SlideInRight } from "../styles/animations";

const PublishersSelfShopsStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      landing: file(relativePath: { eq: "21-shop-landing.png" }) {
        childImageSharp {
          fluid(maxWidth: 1170, maxHeight: 1672, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HomeLanding
        section="Self-serve Shops"
        image={images.landing}
        imageMobile={images.landing}
        link={{
          label: "Get Started",
          attrs: { to: "/contact/", state: { from: "publisher" }, as: Link }
        }}
        bottomMargins="-35%"
        theme={{
          animationPre: "transform: translateY(30%);",
          keyFrames: SlideInRight,
          imageMaxWidth: "700px",
          topPadding: "98px",
          bottomPadding: "240px",
          imageTranslate:
            "translate(calc(100% * 4 / 12), calc(100% * 2.8 / 12))",
          imageMarginTop: 0
        }}
      >
        We Build <br /> The Shop
        <OutlinedText component={Headline80} as="span">
          You Make It A Hit
        </OutlinedText>
      </HomeLanding>

      <HeroTitleOutline
        theme={{
          color: white,
          background: black1,
          width: "420px",
          paddingBottom: "0px",
          paddingTopMobile: "100px"
        }}
        title="Your Shop,"
        subtitle="Your Rules"
      />

      <HeroDivideRight
        theme={{
          width: "800px",
          color: white,
          background: black1,
          lines: brandColor3
        }}
        contentTag={"div"}
      >
        <p>
          If you have a smaller audience size, but still want to dive into the
          world of commerce — our Self-Serve Shops are the solution you need.
        </p>
        <p>
          We’ll empower you with a Shop and our exclusive offers, and you’ll
          take it from there.
        </p>
      </HeroDivideRight>
    </>
  );
};

export default PublishersSelfShopsStatic;
