import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersSelfShopsStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/PublishersSelfShopsDefer")
);

const SelfServeShops = () => (
  <Layout>
    <Seo
      title="Self-Serve eCommerce Platform for Publishers"
      description="Launch a branded eCommerce shop with StackCommerce’s technology and manage the shop with our intuitive commerce toolkit."
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default SelfServeShops;
